<template>
<v-chip small outlined label
        :disabled="disabled"
        :text-color="working ? colours.working : colours.off"
        @click="$emit('click')">
  <v-icon>
    {{ working ? 'mdi-check' : 'mdi-close' }}
  </v-icon>
</v-chip>
</template>

<script>
import { workingColours } from '../../data'

export default {
  name: 'UserWorkingIcon',
  props: {
    working: [Boolean, Number],
    disabled: Boolean
  },
  data() {
    return {
      colours: workingColours
    }
  }
}
</script>

<style scoped>

</style>
